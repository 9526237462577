import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { apis } from 'src/environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/parallel`

    constructor(public http: HttpClient) {
        // Code Here
    }

    getMyWallet(params: any): Observable<any> {
        const url = `${this.baseUrl}/my-wallet`
        return this.http.get<any>(url, { params })
    }

    getPaymentDetail(): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/detail`
        return this.http.get<any>(url)
    }
    // postPayment(): Observable<any> {
    //     const url = `${this.baseUrl}/wallet/my-wallet`
    //     return this.http.post<any>(url, null)
    // }
    // payNow(body: any): Observable<any> {
    //     const url = `${this.baseUrl}/pay-now`
    //     return this.http.post(url, body)
    // }
    payRequest(params: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/my-wallet/pay`, params)
    }
    afterPayresquest(params: any): Observable<any> {
        return this.http.post(`https://test.authorize.net/payment/payment`, params)
    }
}
