import { ApiService } from 'src/app/services/api.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { DataService } from './data.service'
import { Resp } from 'src/app/interfaces/response'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { Router, ActivatedRoute, RouterLink } from '@angular/router'
import { Pagination } from 'src/app/interfaces/response'
import { Transaction } from 'src/app/models/transactions'
import moment, { Moment } from 'moment'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
    selector: 'app-my-wallet',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
    inspection15: Number = 1500
    balance: any
    inspection1: Number = 125
    packageAmount: Number = 0
    editedPackageAmount: Number = this.packageAmount
    isEditing: boolean = false
    fetching: boolean = false
    isPack2Active: boolean = false
    isPack1Active: boolean = false
    dataStatus = 'fetching'
    moment = moment
    reportTime: any
    openingBalance: number = 0
    currentBalance = 0
    pageOpeningBalance = 0
    paymentAmount = 0
    totalBalance: Array<number> = []
    walletList: Array<Transaction> = []
    pagination: Pagination<any>
    modalRefPayNow: BsModalRef
    modalRefRequest: BsModalRef
    modalRef: BsModalRef
    confirmPaymentDetails: any = {}
    paymentDetail: any = {}
    confirmPayment: boolean = false
    paymentFG: FormGroup
    filters: any = {
        startDate: '',
        endDate: '',
        page: 1
    }
    breadCrum = [
        {
            link: '/parallel/transactions/',
            value: 'Transactions'
        }
    ]
    loaderOptions = {
        rows: 5,
        cols: 5,
        colSpans: {
            0: 1
        }
    }
    waiting: {
        search: boolean
        save: boolean
        payNow: boolean
    }

    constructor(
        private ds: DataService,
        public ui: UIHelpers,
        public router: Router,
        private route: ActivatedRoute,
        private alert: IAlertService,
        public cs: ConstantsService,
        private ms: BsModalService,
        public api: ApiService
    ) {
        this.waiting = {
            search: false,
            save: false,
            payNow: false
        }

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }

            if (params.startDate) {
                this.filters.startDate = new Date(params.startDate)
            }

            if (params.endDate) {
                this.filters.endDate = new Date(params.endDate)
            }
            if (params) {
                this.search()
            }
        })
    }
    ngOnInit(): void {
        this.paymentFG = new FormGroup({
            token: new FormControl('', Validators.required)
        })
    }
    checkTotal(total: number, pageOpeningBalance: number) {
        const lastIndex = this.walletList.length - 1
        this.totalBalance[lastIndex] =
            Number(total) + Number(pageOpeningBalance) + Number(this.walletList[lastIndex]?.amount)

        for (let j = lastIndex - 1; j >= 0; j--) {
            this.totalBalance[j] =
                Number(this.totalBalance[j + 1]) + Number(this.walletList[j].amount)
        }
    }

    openPayNowModal(modal: TemplateRef<any>) {
        this.modalRef = this.ms.show(modal, {
            class: 'modal-sm modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    setPagination(page: number) {
        this.filters.page = page
        const params = { ...this.filters }
        // params.endDate = moment(params.endDate).format('YYYY-MM-DD')
        // params.startDate = moment(params.startDate).format('YYYY-MM-DD')
        this.router.navigate(['/student/transactions'], { queryParams: params, replaceUrl: true })
    }
    printdiv() {
        window.print()
    }

    search(): void {
        this.openingBalance = 0
        if (this.filters.startDate !== '' && this.filters.endDate !== '') {
            if (this.filters.endDate < this.filters.startDate) {
                this.alert.error('End Date must be Greater than Start Date')
                return
            }
        }

        this.waiting.search = true

        const params = { ...this.filters }
        if (params.endDate !== '') {
            params.endDate = moment(params.endDate).format('YYYY-MM-DD')
        }
        if (params.startDate !== '') {
            params.startDate = moment(params.startDate).format('YYYY-MM-DD')
        }

        this.ds.getMyWallet(params).subscribe((resp: Resp<any>) => {
            this.waiting.search = false
            if (resp.success === true) {
                this.walletList = resp.data?.data?.data
                this.pagination = resp.data?.data as Pagination
                this.dataStatus = 'done'
                this.openingBalance = resp.data.openingBalance
                this.pageOpeningBalance = resp.data.pageOpeningBalance
                this.currentBalance = resp.data.currentBalance
                this.paymentAmount = this.currentBalance < 0 ? this.currentBalance * -1 : 100

                this.checkTotal(this.openingBalance, resp.data.pageOpeningBalance)
            }
        })
    }

    // payNow(): void {
    //     this.waiting.payNow = true
    //     this.ds.payNow({ amount: this.paymentAmount }).subscribe((resp: Resp<any>) => {
    //         this.waiting.payNow = false
    //         if (resp.success) {
    //             this.modalRef.hide()
    //             window.location.href = resp.data
    //         } else {
    //             this.alert.error(`Can't pay now. Please try again later.`)
    //         }
    //     })
    // }

    forEachInspection() {
        this.isPack2Active = false
        this.isPack1Active = true
        this.packageAmount = 0
    }
    forManyInspection() {
        this.isPack1Active = false
        this.isPack2Active = true
        this.packageAmount = this.paymentDetail.fifteenDayInspectionAmount
    }
    clearAmount() {
        this.isPack1Active = false
        this.isPack2Active = false
        this.packageAmount = 0
        setTimeout(() => {
            this.confirmPayment = false
        }, 500)
    }
    enterEditMode() {
        this.editedPackageAmount = this.packageAmount
        this.isEditing = true
    }
    saveEditedAmount() {
        if (this.packageAmount !== this.inspection15) {
            this.packageAmount = this.editedPackageAmount
            this.isEditing = false
        }
        if (this.packageAmount === this.inspection15) {
            this.isPack2Active = true
            this.isPack1Active = false
            this.packageAmount = this.inspection15
        }
        if (this.packageAmount === 0) {
            this.isPack1Active = false
            this.isPack2Active = false
        }
    }

    openRequestPayNowModal(template: any) {
        this.fetching = true
        this.modalRefPayNow = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
        this.ds.getPaymentDetail().subscribe((resp) => {
            this.fetching = false
            if (resp.success) {
                if (resp.data != null) {
                    this.paymentDetail = resp.data
                    this.paymentDetail.perDayAmount = this.paymentDetail.fifteenDayInspectionAmount / 15
                    if (this.paymentDetail.perDayAmount < this.paymentDetail.singleDayInspectionAmount) {
                        this.paymentDetail.savingPerDay = this.paymentDetail.singleDayInspectionAmount - this.paymentDetail.perDayAmount
                    }
                }
                else {

                }
            }
            else {

            }
        })
    }

    openRequestModal(template: any) {
        this.modalRefRequest = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    closeModals1() {
        if (this.modalRefPayNow) {
            this.modalRefPayNow.hide()
        }

    }

    closeModals2() {
        if (this.modalRefRequest) {
            this.modalRefRequest.hide()

        }
    }

    // openRequetDetailModal(formModal: any) {
    //     this.modalRef = this.ms.show(formModal, {
    //         class: 'modal-lg modal-dialog-centered back-office-panel',
    //         backdrop: 'static',
    //         ignoreBackdropClick: true,
    //         keyboard: false
    //     })
    // }

    // requestPackageDetail(packageDetail: any) {
    //     this.openRequetDetailModal(packageDetail)
    // }

    printDiv() {
        this.reportTime = moment().format('MMMM DD YYYY, h:mm A')
        setTimeout(() => {
            window.print()
        }, 500)
    }
    payRequest() {
        this.waiting.save = true
        let params = {
            amount: this.packageAmount,
            package: true
        }
        if (this.packageAmount !== this.paymentDetail.fifteenDayInspectionAmount) {
            params.package = false
        }
        this.ds.payRequest(params).subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success) {
                this.confirmPaymentDetails = resp.data
                this.confirmPaymentDetails.url = atob(resp.data.url)
                this.paymentFG.controls.token.setValue(resp.data.token)
                this.confirmPayment = true
            }
            else {
                this.alert.error(resp.errors.general)
            }
        })
    }

    submitForm() {
        const form: any = window.document.getElementById("formAuthorizeNetTestPage")
        form.submit()
    }
}
