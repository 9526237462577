<div class="no-print-me">
    <h1 class="title">Transactions</h1>
    <app-breadcrums [data]="breadCrum"></app-breadcrums>
    <div>
        <div class="row" style="display: flex; align-items: flex-end; margin: 15px 0px">
            <div class="col-md-3 cg-input-group">
                <label>Start Date</label>
                <input type="text" class="cg" [(ngModel)]="filters.startDate" bsDatepicker
                    [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                    placeholder="Start Date" />
            </div>
            <div class="col-md-3 cg-input-group">
                <label>End Date</label>
                <input type="text" class="cg" [(ngModel)]="filters.endDate" bsDatepicker
                    [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
                    placeholder="End Date" />
            </div>

            <div class="col cg-input-group d-flex justify-content-between align-items-end">
                <div class="btn-group" style="display: flex" dropdown>
                    <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button"
                        class="cg secondary" (click)="filters.page = 1; search()">
                        Search
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <button class="cg success" style="margin-left: 5px"
                        (click)="openRequestPayNowModal(requestPayNowTemplate)">Recharge Balance</button>

                </div>
            </div>

            <div class="blnc" style="width: 250px">
                <label>Current Balance: </label>
                <h3 class="text-right" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.2-2' }}</h3>
                <h3 class="text-right" *ngIf="currentBalance < 0">-${{ currentBalance * -1 | number: '1.2-2' }}</h3>
            </div>
        </div>
    </div>

    <!-- <div class="d-flex justify-content-between">
    <div class="actions">
        <div class="blnc">
            <label>Opening Balance: </label>
            <h3 class="text-right" *ngIf="openingBalance >= 0">${{ openingBalance | number: '1.0-2' }}</h3>
            <h3 class="text-right" *ngIf="openingBalance < 0">${{ openingBalance * -1 | number: '1.0-2' }}</h3>
        </div>

        <div class="blnc">
            <label>Current Balance: </label>
            <h3 class="text-right" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.0-2' }}</h3>
            <h3 class="text-right" *ngIf="currentBalance < 0">${{ currentBalance * -1 | number: '1.0-2' }}</h3>
        </div>
    </div>
</div> -->
    <div class="box">
        <div class="table-responsive">
            <table class="cg primary">
                <thead>
                    <tr>
                        <th style="width: 20%">Date</th>
                        <th style="width: 20%">Description</th>
                        <th style="width: 20%">Debit</th>
                        <th style="width: 20%">Credit</th>
                        <th style="width: 20%">Balance</th>
                    </tr>
                </thead>

                <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>

                <tbody *ngIf="walletList.length > 0">
                    <tr *ngFor="let d of walletList; let i = index">
                        <!-- <td>{{moment.utc(d.created_at).local().format('MM/DD/yyyy')}}</td> -->
                        <td>{{ d.dated }}</td>
                        <td>{{ d.description }}</td>
                        <td class="amount text-right">
                            <span *ngIf="d.amount < 0">${{ d.amount * -1 | number: '1.2-2' }}</span>
                        </td>
                        <td class="amount text-right">
                            <span *ngIf="d.amount >= 0">${{ d.amount | number: '1.2-2' }}</span>
                        </td>
                        <td class="amount text-right">
                            <span *ngIf="totalBalance[i] >= 0">${{ totalBalance[i] | number: '1.2-2' }}</span>
                            <span *ngIf="totalBalance[i] < 0">-${{ totalBalance[i] * -1 | number: '1.2-2' }}</span>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngIf="dataStatus === 'done' && walletList.length === 0">
                    <tr>
                        <td colspan="6">
                            <app-no-data-found></app-no-data-found>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ng-template #paymentModal>
            <div class="modal-body">
                <h2 class="title">Online Payment</h2>
                <div class="input-group">
                    <label>How much amount you want to pay?</label>
                    <div class="group">
                        <div class="pre">
                            <button class="secondary">USD</button>
                        </div>
                        <input type="text" class="text-right" placeholder="Amount" [(ngModel)]="paymentAmount" />
                    </div>
                </div>

                <!-- <div class="d-flex justify-content-end">
                <button type="button" class="secondary" (click)="modalRef.hide()">Cancel</button>
                <button [disabled]="waiting.payNow" [ngClass]="{ 'in-progress': waiting.payNow }" type="button" class="primary ml-1" (click)="payNow()">
                    Proceed to Payment
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div> -->
            </div>
        </ng-template>

        <app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)"
            *ngIf="dataStatus === 'done'"></app-ipagination>
    </div>
</div>
<div class="print-me report">
    <div class="header">
        <img src="assets/images/logo.png" />
        <div class="details">
            <h2>United Cheerleading &amp; Tumbling</h2>
            <p class="title">Transaction History</p>
            <p class="time">{{ reportTime }}</p>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <div>
            <label>Current Balances :</label>
            <span class="primary" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.2-2' }}</span>
            <span class="primary" *ngIf="currentBalance < 0">-${{ currentBalance * -1 | number: '1.2-2' }}</span>
        </div>
    </div>

    <table class="printable invoice">
        <thead>
            <tr>
                <th class="amount text-center print-head">Date</th>
                <th class="print-head">Description</th>
                <th class="amount text-center print-head">Debit</th>
                <th class="amount text-center print-head">Credit</th>
                <th class="amount text-center print-head">Balance</th>
            </tr>
        </thead>
        <tbody *ngIf="walletList.length > 0">
            <tr *ngFor="let d of walletList; let i = index">
                <td>{{ d.dated | date: 'MM/dd/yyyy' }}</td>
                <td>{{ d.description }}</td>
                <td class="amount text-right">
                    <span *ngIf="d.amount < 0">${{ d.amount * -1 | number: '1.2-2' }}</span>
                </td>
                <td class="amount text-right">
                    <span *ngIf="d.amount > 0">${{ d.amount | number: '1.2-2' }}</span>
                </td>
                <td class="amount text-right">
                    <span *ngIf="totalBalance[i] >= 0">${{ totalBalance[i] | number: '1.2-2' }}</span>
                    <span *ngIf="totalBalance[i] < 0">-${{ totalBalance[i] * -1 | number: '1.2-2' }}</span>
                </td>
            </tr>
        </tbody>

        <tbody *ngIf="dataStatus === 'done' && walletList.length === 0">
            <tr>
                <td colspan="6">
                    <app-no-data-found></app-no-data-found>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #requestPayNowTemplate>
    <ng-container>
    </ng-container>
    <div class="modal-header" [ngStyle]="{ padding: waiting.save ? '0' : '50px' }"
        style="background: rgba(221, 221, 221, 1)" *ngIf="waiting.save || fetching">
        <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </div>
    <div class="modal-body" style="background: rgba(221, 221, 221, 1)" *ngIf="!fetching">
        <div class="container-package" *ngIf="!confirmPayment">
            <div class="packages">
                <span class="borders"></span>
                <div class="pack1" [ngClass]="{ active: isPack2Active }" (click)="forManyInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text">
                        <div>
                            <h2>15 Inspections In</h2>
                            <h1>{{ paymentDetail.fifteenDayInspectionAmount | number : '1.2-2' }}$</h1>
                        </div>
                        <div>
                            <h3>Per inspection {{paymentDetail.perDayAmount | number : '1.2-2'}}$</h3>
                            <h3 *ngIf="paymentDetail.savingPerDay">{{paymentDetail.savingPerDay | number : '1.2-2'}}$
                                per
                                inspection saving
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="pack2" [ngClass]="{ active: isPack1Active }" (click)="forEachInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text" style="padding: 10px 20px; text-align: center">
                        <h2>Add balance to your wallet</h2>
                        <h3>* Pay for each inspection at {{paymentDetail.singleDayInspectionAmount}}$, 15th is free</h3>
                        <!-- <div>
                            <h2>Pay for each inspection at</h2>
                            <h1>{{ inspection1 }}$</h1>
                        </div>
                        <div>
                            <h3>15th is free</h3>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="amount" *ngIf="isPack1Active || isPack2Active">
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="isPack1Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <input style="border: 0; outline: 0; width: 80px; font-size: 24px" type="number"
                        [(ngModel)]="packageAmount" />
                </div>
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap"
                    *ngIf="packageAmount == paymentDetail.fifteenDayInspectionAmount && isPack2Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <h1>{{ packageAmount }}$</h1>
                </div>
                <button [disabled]="packageAmount != 0 && waiting.save" class="pac-pay" type="button"
                    (click)="payRequest()" *ngIf="packageAmount !== 0 && packageAmount != null">Pay Now</button>
            </div>
            <div class="amount" *ngIf="packageAmount <= 0 && !isPack1Active">
                <h2>Please Select Option</h2>
            </div>
        </div>

        <div class="payment-confirmation" *ngIf="confirmPayment">
            <p class="heading">Are you sure to pay ${{packageAmount}}?</p>
            <form [formGroup]="paymentFG" method="post" [action]="this.confirmPaymentDetails.url"
                id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" (ngSubmit)="submitForm()">
                <input type="hidden" name="token" formControlName="token" />
                Continue to Authorize.net to Payment Page
                <button type="submit" class="cg success" style="margin-left: 20px;">Continue to next page</button>
            </form>
        </div>
        <div class="modal-footer" style="background: rgba(221, 221, 221, 1)">
            <button type="button" class="cg primary ms-2" (click)="closeModals1(); clearAmount()">Cancel</button>
        </div>
    </div>
</ng-template>